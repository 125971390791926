import React from "react";
import { EzButton, ComingSoon } from "../button/button";
import "./proj.css";

const Project = (props) => {
  const cn = `project-main ${props.rl}`;
  return (
    <div className={cn}>
      <div className="project-wrapper">
        <div
          data-aos={`fade-${props.rl === "right" ? "left" : "right"}`}
          className="proj-image-wrapper"
        >
          <img className="project-image" src={props.image} alt="" />
        </div>
        <div data-aos={`fade-${props.rl}`} className="project-text">
          <div className="project-title">{props.name}</div>
          <div className="project-long">{props.desc}</div>
          <div className="project-gobutton">
            {props.linkto !== "" ? (
              <EzButton linkto={props.linkto} />
            ) : (
              <ComingSoon />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
