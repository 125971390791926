import React, { useEffect, useState } from "react";
// import Loader from "../../components/loader/loader";
import "./home.css";
import ghost from "../../images/Little ghost.png";
import ProjectsList from "../../components/projSec/projsect";
// import Timeline from "../../components/timeline/timebar";
import Devdiv from "../../components/developer/devdiv";
// import Collab from "../../components/collaborations/collab";
import { ethers } from "ethers";
import presaleAbi from "../../contexts/presaleAbi.json";
// import discord from "../../assets/discord.svg";

import Chains from "../../components/chains/chains";
import Navbar from "../../components/navbar/Navbar";
// import Clock from "../../components/clock/clock";
import Pie from "../../components/graph/pie";
import { PRESALE_CONTRACT_ADDRESS } from "../../contexts/Web3Context";
import TwitterIcon from "../../assets/twitter.svg";
import DiscordIcon from "../../assets/discordPink.svg";

const Home = (props) => {
  const [supply, setSupply] = useState(0);
  const RPC_URL = process.env.REACT_APP_RPC_URL;
  const _signer = new ethers.providers.JsonRpcProvider(RPC_URL);
  const presaleContract = new ethers.Contract(
    PRESALE_CONTRACT_ADDRESS,
    presaleAbi,
    _signer
  );

  useEffect(() => {
    (async () => {
      const sup = await presaleContract.totalSupply();
      console.log(sup);
      setSupply(sup.toNumber());
    })();
  });

  return (
    <div className="home-main">
      {/* {loaded ? (
        <></>
      ) : (
        <div className="home-preloader">
          <Loader />
        </div>
      )} */}
      <Navbar />
      {/* add section divider here */}

      <div className="section-divider" id="home"></div>

      <div className="home-logo">
        <div data-aos="fade-right" className="home-logo-main">
          <div className="logo-image">
            <img src={ghost} alt="logo" />
          </div>
          <div className="eztools">
            <span>Ez</span>Tools
          </div>
        </div>

        <div data-aos="fade-left" className="home-logo-title">
          <div className="inline-wrap">
            Grow your
            <br /> Business <span> With&nbsp;Us</span>
          </div>
        </div>

        <div data-aos="fade-left" className="home-logo-desc">
          <p className="py-4">
            Add value and cater to your project's needs to grow at an
            unbelievably fast pace, with our ever expanding list of products and
            services.
          </p>
          <div className="flex flex-col md:flex-row w-full  items-center mt-2">
            <div className="button-main">
              <a
                href={"mailto:eztoolsofficial@gmail.com"}
                target="_blank"
                rel="noreferrer"
              >
                <button className="the-button">Contact us</button>
              </a>
            </div>{" "}
            <a
              href="https://twitter.com/EzToolsOfficial"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-6 mt-4 md:h-12 md:ml-8"
                src={TwitterIcon}
                alt=""
              />
            </a>
            <a
              href="https://discord.gg/rPkYAvMhgW"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-6 mt-4 md:h-12 md:ml-8"
                src={DiscordIcon}
                alt=""
              />
            </a>
          </div>
        </div>

        {/* <div className="home-subinfo">content here</div> */}
      </div>

      <div className="section-heading heading-responsive">
        {/* Mints are Live! <span> </span> / 2250 sold */}
      </div>
      <div className="section-divider" id="chains"></div>
      {/* <div className="section-divider" id="home"></div> */}
      <div className="home-info">
        <div className="info-body">
          {/* <div className="milestones-heading heading-responsive">
            The&nbsp;<span>Timeline</span>
          </div>
          <div className="milestones">
            <Timeline />
          </div> */}
          <div className="projects-section">
            <div className="projects-heading heading-responsive">
              Supported&nbsp;<span>Chains</span>
            </div>
            <div data-aos="fade-up" className="projects-body">
              {/* <FadeIn> */}
              <Chains />
              {/* </FadeIn> */}
            </div>
          </div>
          <div className="section-divider" id="projects"></div>
          <div className="projects-section">
            <div className="projects-heading heading-responsive">
              Our&nbsp;<span>Projects</span>
            </div>
            <div className="projects-body">
              {/* <FadeIn> */}
              <ProjectsList />
              {/* </FadeIn> */}
            </div>
          </div>
        </div>
      </div>
      <div className="section-divider" id="developers"></div>
      <div className="developer-main">
        <div className="inline-wrap  heading-responsive">
          Meet The <span>Developers</span>
        </div>
      </div>
      <div className="developer-card-area">
        <Devdiv />
      </div>
      <div className="section-divider" id="collaborators"></div>
      <div className="developer-main">
        <div className="inline-wrap  heading-responsive">
          Our <span>Community</span>
        </div>
      </div>
      <div className="our-stats">
        <div className="our-stats-body">
          <div className="stats-body-inner">
            <div data-aos="zoom-in" className="stats-body-number">
              5+
            </div>
            <div className="stats-body-support">
              Projects developed in our ecosystem since 2022
            </div>
          </div>

          <div className="stats-body-inner">
            <div data-aos="zoom-in" className="stats-body-number">
              500+
            </div>
            <div className="stats-body-support">Active customers</div>
          </div>

          <div className="stats-body-inner">
            <div data-aos="zoom-in" className="stats-body-number">
              500+
            </div>
            <div className="stats-body-support">
              Developer hours contributed to projects in our ecosystem
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section-divider"></div>
      <div className="developer-main">
        <div className="inline-wrap  heading-responsive">
          Community <span>Testimonies</span>
        </div>
      </div> */}
      <div className="section-divider"></div>
      {/* <div className="ezdevs">
        <div className="developer-main ezdevs">
          <div className="inline-wrap heading-responsive">
            Ez&nbsp;<span>Devs</span>
          </div>
        </div>
        <div className="ezdevs-body">
          Need custom dev support other than our standardised ones ? Feel free
          to reach out to our team and we'll work with you to build your vision.
        </div>
      </div> */}
    </div>
  );
};

export default Home;
