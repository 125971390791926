import React, { useContext, useEffect, useState } from "react";
import Web3Context from "../../contexts/Web3Context";
import styles from "./collection.module.css";
import axios from "axios";
import NFTPopup from "../../components/popup/NFTPopup";
import Navbar from "../../components/navbar/Navbar";
import { NavLink } from "react-router-dom";

const Collection = (props) => {
  const { account, userNFTs, connectWallet } = useContext(Web3Context);
  const [ghosts, setGhosts] = useState(null);
  const [ghostMeta, setGhostMeta] = useState(null);
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  // console.log(userNFTs);
  useEffect(() => {
    const Nfts = async () => {
      setGhosts(userNFTs?.data);
      setBalance(userNFTs?.balance);
    };

    account && Nfts();
  }, [account, userNFTs]);

  const getMetadata = async (token) => {
    setLoading(true);
    setOpen(true);
    const res = await axios.get(`https://api.eztools.one/metadata/${token}`);
    console.log(res.data);
    setGhostMeta(res.data);
    setLoading(false);
  };
  const [addArray, setArray] = React.useState([
    { tokenID: 1 },
    { tokenID: 2 },
    { tokenID: 3 },
    { tokenID: 4 },
    { tokenID: 5 },
    { tokenID: 6 },
    { tokenID: 1 },
    { tokenID: 2 },
    { tokenID: 3 },
    { tokenID: 4 },
    { tokenID: 5 },
    { tokenID: 6 },
    { tokenID: 1 },
    { tokenID: 2 },
    { tokenID: 3 },
    { tokenID: 4 },
    { tokenID: 5 },
    { tokenID: 6 },
  ]);
  console.log(ghosts);
  const shortAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4,
      address.length
    )}`;
  };
  useEffect(() => {
    if (!account) {
      setConnected(false);
      return;
    }
    setConnected(true);
  }, [account]);

  const connect = () => {
    connectWallet();
    setConnected(true);
  };
  return (
    <>
      <Navbar />
      <div className="section-divider" id="home"></div>

      <div className={styles.main}>
        <div className={styles.header}>
          <h1>Your NFTs</h1>
          {/* {connected ? (
            <div className={styles.connect}>
              {shortAddress(account ? account : "")}
            </div>
          ) : (
            <button className={styles.connect} onClick={connect}>
              Connect Wallet
            </button>
          )} */}
        </div>
        {!connected && (
          <>
            <button
              className="wallet-connect-button text-white"
              onClick={connectWallet}
              style={{
                margin: "auto",
                fontSize: "1rem",
                marginTop: "5rem",
              }}
            >
              Connect your wallet
            </button>
          </>
        )}

        {!connected ? (
          <></>
        ) : !ghosts ? (
          <>
            {" "}
            <div className={styles.loading}>loading...</div>
          </>
        ) : ghosts && ghosts?.length !== 0 ? (
          <div className={styles.gridwrap}>
            {ghosts?.map((ghost, index) => {
              return (
                <div className={styles.addlist_centering} key={index}>
                  <div className={styles.addlist_item}>
                    <div className={styles.image_top}>
                      <img
                        onClick={() => {
                          getMetadata(ghost?.tokenId);
                        }}
                        src={ghost?.tokenImg}
                        alt=""
                      />
                    </div>
                    <div className={styles.addrow} key={index}>
                      EzTools NFT{" "}
                      <span className={styles.bpink}> #{ghost?.tokenId}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.nonft}>
            <div>No NFTs owned</div>
            {/* <NavLink to="/mint"> */}
            <button
              className={styles.connect}
              // onClick={connect}
            >
              Mint coming soon!
            </button>
            {/* </NavLink> */}
          </div>
        )}
      </div>
      <NFTPopup
        open={open}
        ghost={ghostMeta}
        setModal={setOpen}
        loading={loading}
      />
    </>
  );
};

export default Collection;
