import React, { useEffect, useState } from "react";
import "./counter.css";

const Counter = ({ vouchers }) => {
  const [count, setCount] = useState(null);
  // const [total, setTotal] = useState(30);
  const total = 30;

  useEffect(() => {
    setCount(vouchers);
  }, [vouchers]);

  function getWidth(value, total) {
    return `${parseInt((value / total) * 100)}%`;
  }
  return (
    <div className="counter-main">
      <div className="counter-count">
        <div
          className="counter-span"
          style={{ width: getWidth(count, total) }}
        ></div>
      </div>
      <div className="counter-stats">
        {count} of {total} vouchers given out
      </div>
    </div>
  );
};

export default Counter;
