import React from "react";
import "./developer.css";
import cont1 from "../../images/svgs/contour1.svg";
import cont2 from "../../images/svgs/contour2.svg";
import cont3 from "../../images/svgs/contour3.svg";
import cont4 from "../../images/svgs/contour4.svg";
import cont5 from "../../images/svgs/contour5.svg";
import cont6 from "../../images/svgs/contour6.svg";
import cont7 from "../../images/svgs/contour7.svg";
import cont8 from "../../images/svgs/contour8.svg";

const DevCard = (props) => {
  const imgarray = [cont1, cont2, cont3, cont4, cont5, cont6, cont7, cont8];
  let ind = props.c;

  let twtstat = props.twitter !== "" && props.twitter !== undefined;
  let gitstat = props.github !== "" && props.github !== undefined;

  return (
    <div data-aos="fade-up" className="devcard-main">
      <div className="devcard-head">
        {" "}
        <img src={imgarray[ind]} alt="" />
      </div>
      <div className="devcard-body">
        {" "}
        <div className="devcard-body-name">{props.name}</div>{" "}
        <div className="devcard-body-desc">{props.desc}</div>
      </div>
      <div className="devcard-tail">
        {gitstat ? (
          <a href={props.github} target="_blank" rel="noreferrer">
            <img
              src="https://img.icons8.com/ios/50/ffffff/github--v1.png"
              alt="github"
            />
          </a>
        ) : (
          <></>
        )}
        {twtstat ? (
          <a href={props.twitter} target="_blank" rel="noreferrer">
            <img
              src="https://img.icons8.com/ios/50/ffffff/twitter-circled--v1.png"
              alt="twitter"
            />
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DevCard;
