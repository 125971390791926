import React from "react";
import DevCard from "./developer";

import "./devdiv.css";

const Devdiv = (props) => {
  return (
    <div className="devdiv">
      <DevCard
        c={0}
        name={"Ace"}
        desc={
          " Solidity Developer with experience working on several Harmony projects. He leads the Web3 part of the project and is responsible for all the deployed contracts."
        }
        github={"https://github.com/AceVikings"}
        twitter={"https://twitter.com/vikings_ace"}
      />
      <DevCard
        c={1}
        name={"Acadia"}
        desc={
          "Full stack Developer with experience working on several Harmony projects. He leads the Front-end/Back-end part of the project and is responsible for all the integrations."
        }
        github={"https://github.com/MysteriousAcadia"}
        twitter={"https://twitter.com/notAcadia"}
      />
      {/* <DevCard c={2} 
      name={"Loechii"}
      desc={"Marketing Head for EzTools, an active member in the space who has been involved with many other major projects. She is skilled in networking and community management and is our person of contact for collaborations."}
      github={""}
      twitter={"https://twitter.com/LoeChii"}/> */}
      <DevCard
        c={3}
        name={"Oiseau"}
        desc={
          "Front end Developer for EzTools with experience in multiple web2 and web3 projects. His work strikes a balance between functional and aesthetic design."
        }
        github={"https://github.com/krishanu-xc"}
        twitter={""}
      />
      <DevCard
        c={4}
        name={"Knight"}
        desc={
          "Full stack developer with experience in multiple web2 and web3 projects. He does Front-end and helps with integrations as well."
        }
        github={"https://github.com/Sanskar0520"}
        twitter={""}
      />
      {/* <DevCard
        c={5}
        name={"Sk"}
        desc={
          "Full stack developer with experience working on several ethereum and polygon projects."
        }
        github={"https://github.com/subhamkumarsadhukhan"}
        twitter={""}
      /> */}

      {/* <DevCard
        c={6}
        name={"Anyx"}
        desc={
          "Solidity Developer. Experienced in building Web3 games and NFT projects. Experience in writing test-suites"
        }
        github={"https://github.com/0xAnyx"}
        twitter={""}
      /> */}
      {/* <DevCard
        c={7}
        name={"Void"}
        desc={
          "Full stack developer with experience in multiple dApp projects and NFT generation on ethereum and polygon."
        }
        github={"https://github.com/Soumojit28"}
        twitter={""}
      /> */}
    </div>
  );
};

export default Devdiv;
