import { useContext, useEffect, useState } from "react";
import Web3Context from "../../contexts/Web3Context";
import "./Mint.css";
import ghost_crown from "../../images/ghost_crown.png";
import Navbar from "../../components/navbar/Navbar";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

export default function Mint() {
  const {
    account,
    balance,
    connectWallet,
    whitelistMint,
    mint,
    whitelistPeriod,
    price,
    update,
    NFTBalance,
  } = useContext(Web3Context);
  const [userWhitelists, setUserWhitelists] = useState(0);
  const [loading, setLoading] = useState(true);
  const [period, setWhitelistPeriod] = useState(true);
  const [checkoutAmount, setCheckoutAmount] = useState(null);
  const [mintAmount, setMintAmount] = useState(null);
  const [nftPackValue, setNftPackValue] = useState(1);
  const [nftBalance, setNftBalance] = useState();
  const [nftCheckoutAmount, setNftCheckoutAmount] = useState(4680);
  useEffect(() => {
    setLoading(true);
    const getUserWhitelists = async () => {
      const [nftBal] = await Promise.all([NFTBalance()]);
      calculate();
      setNftBalance(nftBal);
      setLoading(false);
    };
    account && getUserWhitelists();
  }, [account, update]);

  async function calculate() {
    // if (userWhitelists < mintAmount) {
    //   toast.error("Not enough whitelist vouchers");
    //   return;
    // }
    console.log("here");

    const [p, wlp] = await price();
    console.log([p, wlp]);
    console.log(p, nftPackValue);
    const totalPrice = p * nftPackValue;
    const totalWLPrice = wlp * mintAmount;
    setCheckoutAmount(totalWLPrice);
    setNftCheckoutAmount(totalPrice);
  }

  useEffect(() => {
    console.log("calc");
    calculate();
  }, [mintAmount, nftPackValue]);
  function NftPack({ value, setValue }) {
    return (
      <div
        className={`h-16 w-1/3 nft-select-value text-xl ${
          nftPackValue === value && "nft-selected"
        }`}
        onClick={() => {
          calculate(value);
          setValue(value);
        }}
      >
        {value}
      </div>
    );
  }

  console.log(nftPackValue);

  return (
    <>
      <div className="home-main">
        <Navbar />
        <div className="section-divider" id="home"></div>
        <div className="home-logo wl-landing p-4 sm:p-16">
          <div data-aos="fade-right" className="home-logo-main">
            <div className="container text-center">
              <div className="mint-head">
                Mint your <span>NFT</span>
              </div>
              {!account ? (
                <button
                  className="wallet-connect-button"
                  onClick={connectWallet}
                  style={{
                    margin: "auto",
                    fontSize: "1rem",
                    marginTop: "5rem",
                  }}
                >
                  Connect your wallet
                </button>
              ) : (
                <>
                  {userWhitelists === 0 ? (
                    <div className="flex flex-row gap-4 mt-8">
                      <NftPack value={1} setValue={setNftPackValue} />
                      <NftPack value={3} setValue={setNftPackValue} />
                      <NftPack value={5} setValue={setNftPackValue} />
                      <NftPack value={10} setValue={setNftPackValue} />
                    </div>
                  ) : (
                    <div className="flex flex-row gap-4 justify-center my-10">
                      <input
                        type="number"
                        min="0"
                        step="1"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="text-lg p-2 max-h-16 input-value"
                        placeholder="Number of NFTs"
                        onChange={(e) => setMintAmount(e.target.value)}
                      />
                      <button
                        className="the-button text-sm"
                        onClick={calculate}
                      >
                        Calculate
                      </button>
                    </div>
                  )}
                  {userWhitelists !== 0 && checkoutAmount > 0 && !loading && (
                    <div className="row">
                      <div className="text-center">
                        <div className="text-3xl mt-10 mb-4">Price</div>
                        <hr></hr>

                        <hr></hr>
                        <div className="flex flex-row text-xl justify-between mt-4">
                          <div>Total </div>
                          <div>{checkoutAmount} ONE</div>
                        </div>
                      </div>

                      <button
                        onClick={() => whitelistMint(mintAmount)}
                        className="the-button text-xl mt-10 sm:mt-0"
                      >
                        Whitelist Mint
                      </button>
                    </div>
                  )}
                  {userWhitelists === 0 && !loading && (
                    <div className="row">
                      <div className="text-center">
                        <div className="text-3xl mt-10 mb-4">Price</div>
                        <hr></hr>

                        <hr></hr>
                        <div className="flex flex-row text-xl justify-between mt-4">
                          <div>Total </div>
                          <div>{nftCheckoutAmount} ONE</div>
                        </div>
                      </div>

                      <button
                        onClick={() => mint(nftPackValue)}
                        className="the-button text-xl mt-10 sm:mt-0"
                      >
                        Mint
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div
            data-aos="fade-left"
            className="home-logo-title"
            id="rem-padding"
          >
            <div className="inline-wrap" id="hero-title">
              <img src={ghost_crown} alt="" className="mint-img" />
              <div className="text-3xl sm:text-5xl my-4 text-center">
                Your <span style={{ textDecoration: "none" }}>Whitelists:</span>{" "}
                {userWhitelists}
              </div>
            </div>
          </div>
        </div>
        {account && (
          <div className="-mt-4 mb-16 mx-auto">
            <div className="text-5xl text-center font-bold">
              {" "}
              You have{" "}
              <span className="text-7xl tracking-wider">{nftBalance}</span>{" "}
              EzTool NFT
              {nftBalance == 1 ? "" : "s"}{" "}
            </div>
            <div
              style={{ color: "hotpink" }}
              className="text-lg text-center font-semibold"
            >
              <NavLink to="/collection">(View my NFTs)</NavLink>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
