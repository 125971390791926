import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Home from "./pages/home/home";
import AOS from "aos";
// import { ToastContainer, toast } from "react-toastify";
import "aos/dist/aos.css";
import { Web3Provider } from "./contexts/Web3Context";
import Whitelist from "./pages/Whitelist/Whitelist";
import Mint from "./pages/Mint/Mint";
import { Navigate } from "react-router-dom";
import Collection from "./pages/collections";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: "ease-in-sine",
      delay: 50,
    });
  }, []);
  return (
    <Web3Provider>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/whitelist" element={<Whitelist />} />
          <Route exact path="/mint" element={<Mint />} />
          <Route
            exact
            path="*"
            element={<Navigate to="/whitelist" replace={true} />}
          />
          <Route exact path="/collection" element={<Collection />} />
        </Routes>
      </BrowserRouter>
    </Web3Provider>
  );
}

export default App;
