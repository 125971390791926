import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./pie.css";

ChartJS.register(ArcElement);

export default function Pie({ caption_jsx, minted, total }) {
  const data = {
    labels: ["Treasury", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        data: [minted, total - minted],
        backgroundColor: ["rgba(255, 105, 180, 0.2)", "rgba(50, 50, 50, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
        borderAlign: "inner",
        cutout: "60%",
      },
    ],
  };

  return (
    <div className="pie-wrap">
      <Doughnut data={data} />
      <div className="inner-content">
        <div className="data-stats">
          {minted}
          <div className="small-cap">/ {total}</div>
        </div>
        {caption_jsx}
      </div>
    </div>
  );
}
