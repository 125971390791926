import React from "react";
import "./graph.css";
import Pie from "./pie";

const GraphCalc = (props) => {
  const [minEarnings, setMinEarnings] = React.useState(0);
  const [maxEarnings, setMaxEarnings] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [revenueInput, setRevenueInput] = React.useState(0);
  const [comboInput, setComboInput] = React.useState(0);
  const [commonInput, setCommonInput] = React.useState(0);
  const [customCombo, setCustomCombo] = React.useState(0);
  const [commonCombo, setCommonCombo] = React.useState(0);
  const totalMints = 2225;

  function getValues(revenue, yourCommon, yourCombo) {
    // maximum case, you own all combo NFTs, rest are common NFTs
    // minimum case, you own common and combo NFTs, rest are combo NFTs, no common NFTs
    // [common, combo]
    const max_split = [totalMints - yourCombo, yourCombo];
    const min_split = [yourCommon, totalMints - yourCommon];
    const min_shares = min_split[0] + min_split[1] * 2;
    const max_shares = max_split[0] + max_split[1] * 2;
    const min_revPerShare = revenue / min_shares;
    const max_revPerShare = revenue / max_shares;

    const min_earnings =
      min_revPerShare * yourCombo * 2 + min_revPerShare * yourCommon;
    setMinEarnings(min_earnings.toFixed(2));
    const max_earnings =
      max_revPerShare * yourCombo * 2 + max_revPerShare * yourCommon;
    setMaxEarnings(max_earnings.toFixed(2));
  }

  function checkPhoneKey(key) {
    return (
      (key >= "0" && key <= "9") ||
      ["ArrowLeft", "ArrowRight", "Delete", "Backspace"].includes(key)
    );
  }

  const changeCombo = (e) => {
    let num = parseInt(e.target.value);
    num = num ? num : 0;
    let maxCombo = totalMints - commonCombo;
    setCustomCombo(num < maxCombo / 5 ? num : parseInt(maxCombo / 5));
  };

  const changeCommon = (e) => {
    let num = parseInt(e.target.value);
    num = num ? num : 0;
    let maxCommon = totalMints - customCombo * 5;
    setCommonCombo(num < maxCommon ? num : maxCommon);
  };

  const changeRevenue = (e) => {
    let num = parseInt(e.target.value);
    num = num ? num : 0;
    setRevenueInput(num);
    const holderRatio = 0.2;
    setSliderValue(e.target.value);
  };

  React.useEffect(() => {
    commonInput === 0
      ? setCommonCombo(0)
      : commonInput === 1
      ? setCommonCombo(1)
      : commonInput === 2
      ? setCommonCombo(3)
      : commonInput === 3
      ? setCommonCombo(5)
      : setCommonCombo(commonCombo);

    comboInput === 0
      ? setCustomCombo(0)
      : comboInput === 1
      ? setCustomCombo(1)
      : comboInput === 2
      ? setCustomCombo(2)
      : comboInput === 3
      ? setCustomCombo(3)
      : setCustomCombo(customCombo);
  }, [comboInput, commonInput]);

  return (
    <div className="graph-main">
      <div className="control-panel">
        <div className="common-combo">
          <div className="common-nft">
            <div className="common-nft-title">Common NFT</div>
            <div className="common-body">
              <div
                className={`nft-select-value ${
                  commonInput === 0 && "nft-selected"
                }`}
                onClick={() => setCommonInput(0)}
              >
                none
              </div>
              <div
                className={`nft-select-value ${
                  commonInput === 1 && "nft-selected"
                }`}
                onClick={() => setCommonInput(1)}
              >
                1
              </div>
              <div
                className={`nft-select-value ${
                  commonInput === 2 && "nft-selected"
                }`}
                onClick={() => setCommonInput(2)}
              >
                3
              </div>
              <div
                className={`nft-select-value ${
                  commonInput === 3 && "nft-selected"
                }`}
                onClick={() => setCommonInput(3)}
              >
                5
              </div>
              <div
                className={`nft-select-value value-custom ${
                  commonInput === 4 && "nft-selected"
                }`}
                onClick={() => setCommonInput(4)}
              >
                <input
                  onChange={changeCommon}
                  className="revenue-input"
                  value={commonCombo}
                  onKeyDown={(e) => {
                    if (!checkPhoneKey(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="common-nft">
            <div className="common-nft-title combo-after">Combo NFT</div>
            <div className="common-body">
              <div
                className={`nft-select-value ${
                  comboInput === 0 && "nft-selected"
                }`}
                onClick={() => setComboInput(0)}
              >
                none
              </div>
              <div
                className={`nft-select-value ${
                  comboInput === 1 && "nft-selected"
                }`}
                onClick={() => setComboInput(1)}
              >
                1 set
              </div>
              <div
                className={`nft-select-value ${
                  comboInput === 2 && "nft-selected"
                }`}
                onClick={() => setComboInput(2)}
              >
                2 sets
              </div>
              <div
                className={`nft-select-value ${
                  comboInput === 3 && "nft-selected"
                }`}
                onClick={() => setComboInput(3)}
              >
                3 sets
              </div>
              <div
                className={`nft-select-value value-custom ${
                  comboInput === 4 && "nft-selected"
                }`}
                onClick={() => setComboInput(4)}
              >
                <input
                  onChange={changeCombo}
                  className="revenue-input"
                  value={customCombo}
                  onKeyDown={(e) => {
                    if (!checkPhoneKey(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="revenue-wrap">
          <div className="range-wrap-custom">
            <div
              className="range-wrap-track"
              style={{ width: revenueInput / 1000 + "%" }}
            />
            <input
              type="range"
              name="revenue"
              id="revenue"
              min={0}
              max={100000}
              onChange={changeRevenue}
              value={revenueInput}
            />
          </div>

          <div className="rev-calc">
            <div className="rev our-rev">${revenueInput}</div>
            <div className="rev rev-ratio">x 20% =</div>
            <div className="rev your-rev">
              ${(revenueInput * 0.2).toFixed(0)}
            </div>
          </div>
          <div className="pool-desc">
            <div className="your-pool">
              You own <span>{customCombo * 10 + commonCombo}</span> effective
              shares out of the total effective share pool
            </div>
            <div className="your-sub">
              The total effective share pool varies between {totalMints} and{" "}
              {totalMints * 2}
            </div>
          </div>
        </div>
      </div>
      <div className="you-own">
        You own <span>{commonCombo}</span> common NFTs and{" "}
        <span>
          {customCombo} set{customCombo !== 1 ? "s" : ""}
        </span>{" "}
        of custom NFTs
      </div>
      <div className="calc-wrap">
        <button
          onClick={() =>
            getValues(revenueInput * 0.2, commonCombo, customCombo * 5)
          }
          className="the-button"
        >
          Calculate
        </button>
      </div>
      <div className="calc-wrap ye">Estimated Earnings</div>
      <div className="earn-wrap">
        <div className="earnings-box eb-min">{minEarnings}</div>
        <span>$</span>
        <div style={{ margin: "0 10px 0 10px" }}>-</div>
        <div className="earnings-box eb-max">{maxEarnings}</div>
        <span>$</span>
      </div>
      <div className="you-own footnote">
        *Calculations are assuming the fact that all {totalMints} NFTs are
        minted and staked. The actual number of NFTs minted and staked may be
        less. Consequently, your earnings may be higher than these values.
      </div>

      <br />
      <br />
      {/* <Pie /> */}
    </div>
  );
};

export default GraphCalc;
