import { createContext, useEffect, useState } from "react";
import { Contract, ethers } from "ethers";
import whitelistAbi from "./voucherAbi.json";
import presaleAbi from "./presaleAbi.json";
import {
  Provider as MulticallProvider,
  Contract as MulticallContract,
} from "ethers-multicall";
import { toast } from "react-toastify";
const Web3Context = createContext();

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "https://api.eztools.one";

const RPC_URL = process.env.REACT_APP_RPC_URL;
const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
const NATIVE_CURRENCY = {
  name: process.env.REACT_APP_NATIVE_CURRENCY_NAME,
  symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL, // 2-6 characters long
  decimals: 18,
};

const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME;
const MULTI_CALL_ADDRESS = "0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507";
export let WHITELIST_CONTRACT_ADDRESS =
  process.env.REACT_APP_WHITELIST_CONTRACT_ADDRESS;
export let PRESALE_CONTRACT_ADDRESS =
  process.env.REACT_APP_PRESALE_CONTRACT_ADDRESS;
export let MINT_CONTRACT_ADDRESS = "0x4b8d05734E77E7475a02624B1DC3968Ff4feec8E";

export const Web3Provider = (props) => {
  const [account, setAccount] = useState(false);
  const [signer, setSigner] = useState();
  const [contractObjects, setContractObjects] = useState();
  const [update, setUpdate] = useState(0);
  const [userNFTs, setUserNFTs] = useState(null);
  const [minted, setMinted] = useState(false);
  const functionsToExport = {};
  const onAccountsChanged = async (accounts) => {
    setAccount(accounts[0]);
    const provider = new ethers.providers.JsonRpcProvider(window.ethereum);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };

  useEffect(() => {
    try {
      let _signer;
      if (account) {
        let provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );
        _signer = provider.getSigner();
      } else {
        _signer = new ethers.providers.JsonRpcProvider(RPC_URL);
      }
      const whitelistContract = new ethers.Contract(
        WHITELIST_CONTRACT_ADDRESS,
        whitelistAbi,
        _signer
      );
      const mintContract = new ethers.Contract(
        MINT_CONTRACT_ADDRESS,
        presaleAbi,
        _signer
      );
      const presaleContract = new ethers.Contract(
        PRESALE_CONTRACT_ADDRESS,
        presaleAbi,
        _signer
      );
      const _contractObjects = {
        whitelistContract,
        presaleContract,
        mintContract,
      };
      setContractObjects(_contractObjects);
    } catch (e) {
      console.log(e);
    }
  }, [signer]);
  // useEffect(() => {
  //   const _signer = new ethers.providers.JsonRpcProvider(RPC_URL);

  //   const whitelistContract = new ethers.Contract(
  //     WHITELIST_CONTRACT_ADDRESS,
  //     whitelistAbi,
  //     _signer
  //   );
  //   const _contractObjects = {
  //     whitelistContract,
  //   };
  //   setContractObjects(_contractObjects);
  // }, []);
  const setupMultiCallContract = async (contractAddress, abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const ethcallProvider = new MulticallProvider(provider);
    await ethcallProvider.init();
    ethcallProvider._multicallAddress = MULTI_CALL_ADDRESS;
    const multicallContract = new MulticallContract(
      contractAddress,
      abi || whitelistAbi
    );
    return [ethcallProvider, multicallContract];
  };
  const addNewChain = async () => {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${CHAIN_ID.toString(16)}`,
          rpcUrls: [RPC_URL],
          chainName: CHAIN_NAME,
          nativeCurrency: NATIVE_CURRENCY,
        },
      ],
    });
  };
  const switchCain = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
    });
  };
  const promptChain = async () => {
    try {
      await switchCain();
    } catch (e) {
      await addNewChain();
      await switchCain();
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const _signer = provider.getSigner();
    setSigner(_signer);
  };
  const onChainChanged = async (chainID) => {
    await promptChain();
  };

  functionsToExport.connectWallet = async (defaultAccount = -1) => {
    const { ethereum } = window;
    if (!ethereum) {
      toast.error("You need a wallet to continue!");
      return;
    }

    if (ethereum) {
      await ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await ethereum.request({ method: "eth_accounts" });
      await promptChain();
      ethereum.on("chainChanged", onChainChanged);
      ethereum.on("accountsChanged", onAccountsChanged);
      setAccount(accounts[0]);
      toast.success("Wallet Connected!");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const _signer = provider.getSigner();
      setSigner(_signer);
    }
  };

  functionsToExport.balance = async () => {
    try {
      const balance = await contractObjects.whitelistContract.balanceOf(
        account
      );
      return balance.toNumber();
    } catch (e) {
      console.log(e.message);
    }
  };

  functionsToExport.givenVouchers = async () => {
    try {
      const vouchers = await contractObjects?.whitelistContract?.totalSupply();

      return vouchers.toNumber();
    } catch (e) {
      console.log(e.message);
    }
  };
  functionsToExport.NFTBalance = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      let _signer = provider.getSigner();
      const presaleContract = new ethers.Contract(
        PRESALE_CONTRACT_ADDRESS,
        presaleAbi,
        _signer
      );
      const balance = await presaleContract?.balanceOf(account);
      return balance.toNumber();
    } catch (e) {
      return 0;
    }
  };

  functionsToExport.whitelistMint = async (mintAmount) => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      let _signer = provider.getSigner();
      const presaleContract = new ethers.Contract(
        PRESALE_CONTRACT_ADDRESS,
        presaleAbi,
        _signer
      );
      // const whitelistPeriod = await presaleContract.whitelistPeriod();
      // if (!whitelistPeriod) {
      //   return toast.error("Not Whitelist period");
      // }
      if (mintAmount < 1) {
        return toast.error("minimum mint amount is 1");
      }
      toast.loading("Placing transaction");
      const isApproved =
        await contractObjects?.whitelistContract?.isApprovedForAll(
          account,
          PRESALE_CONTRACT_ADDRESS
        );

      if (!isApproved) {
        toast(`Approving Pre sale Contract`);
        const approveIt =
          await contractObjects?.whitelistContract?.setApprovalForAll(
            PRESALE_CONTRACT_ADDRESS,
            true
          );
        await approveIt.wait();
        toast(`Presale Contract Approved!`);
      }
      const balance = (
        await contractObjects?.whitelistContract?.balanceOf(account)
      ).toNumber();
      console.log("Placing transaction", account);
      let [multicallProvider, multicallContract] = await setupMultiCallContract(
        WHITELIST_CONTRACT_ADDRESS
      );
      let list = [];
      if (mintAmount <= balance && mintAmount != 0) {
        for (let i = 0; i < mintAmount; i++)
          list.push(multicallContract.tokenOfOwnerByIndex(account, i));
        list = (await multicallProvider?.all(list)).map((e) => e.toString());

        let price = await presaleContract.WLPRICE();
        console.log(list, price);
        const n = await presaleContract.wlMint(list, {
          value: price.mul(list.length),
        });
        await n.wait();
        toast.dismiss();
        toast.success("Purchase Complete");
      } else if (mintAmount == 0) {
        toast.dismiss();
        toast.error(`Minimum mint amount is 1`);
      } else if (mintAmount > balance) {
        toast.dismiss();
        toast.error(`Mint Amount must less or equal to available whitelist`);
      }
      setUpdate((u) => u + 1);
    } catch (e) {
      toast.dismiss();
      if (e.error) return toast.error(e.error.data.message);
      if (e.reason) return console.log(e.reason);
      console.log(e.message);
    }
  };

  functionsToExport.mint = async (count) => {
    try {
      if (count < 1) {
        return toast.error("Minimum Mint amount is 1 NFT");
      }
      let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      let _signer = provider.getSigner();
      const presaleContract = new ethers.Contract(
        PRESALE_CONTRACT_ADDRESS,
        presaleAbi,
        _signer
      );
      // const whitelistPeriod = await presaleContract.whitelistPeriod();
      // console.log(whi)
      // if (whitelistPeriod) return toast.error("Public Mint not started yet");
      if (count > 10) return toast.error("Quantity must be less then equal 10");
      toast.loading("Placing transaction");
      const price = await presaleContract.PRICE();
      const maxSupply = await presaleContract.MAX_SUPPLY();
      const totalSupply = await presaleContract.totalSupply();
      if (maxSupply - totalSupply - count >= 0) {
        const n = await presaleContract.mint(count, {
          value: price.mul(count),
        });
        await n.wait();
        setMinted(true);
        toast.dismiss();
        toast.success("Purchase Complete");
      } else {
        toast.dismiss();
        toast.error(`Only ${maxSupply - totalSupply} NFTs Remaining`);
      }
      setUpdate((u) => u + 1);
    } catch (e) {
      toast.dismiss();
      console.log(e.message);
    }
  };
  functionsToExport.getNfts = async () => {
    try {
      console.log(contractObjects?.mintContract);
      const userBalance = parseInt(
        (await contractObjects?.mintContract?.balanceOf(account)).toString()
      );
      // const userBalance = 10;
      console.log(userBalance?.toString());
      const [multicallProvider, multicallContract] =
        await setupMultiCallContract(MINT_CONTRACT_ADDRESS, presaleAbi);
      let tokenCalls = [];
      for (let i = 0; i < userBalance; i++) {
        tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
      }
      const userTokens = (await multicallProvider?.all(tokenCalls)).map((e) =>
        e.toString()
      );
      // const userTokens = [1, 2, 3, 4, 5, 6];
      const userTokenDetails = await Promise.all(
        userTokens?.map(async (token) => {
          // const res = await axios.get(
          //   `https://api.doge.atlantys.app/metadata/${token}`
          // );
          // console.log(res);
          const tokenData = {
            tokenImg: `${BACKEND_URL}/image/${token}`,
            tokenId: token,
          };
          return tokenData;
        })
      );
      console.log(userTokenDetails);
      return { data: userTokenDetails, balance: userBalance?.toString() };
    } catch (e) {
      console.log(e);
    }
  };
  functionsToExport.whitelistPeriod = async () => {
    let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    let _signer = provider.getSigner();
    const presaleContract = new ethers.Contract(
      PRESALE_CONTRACT_ADDRESS,
      presaleAbi,
      _signer
    );
    const whitelistPeriod = await presaleContract.whitelistPeriod();
    return whitelistPeriod;
  };
  functionsToExport.price = async () => {
    let provider = new ethers.providers.JsonRpcProvider(
      "https://polygon-rpc.com"
    );
    const presaleContract = new Contract(
      MINT_CONTRACT_ADDRESS,
      presaleAbi,
      provider
    );
    let price, wlprice;
    wlprice = await presaleContract.CURRENT_PRICE();
    return [wlprice.toString(), 0];
  };

  useEffect(() => {
    const NFTS = async () => {
      const nftData = await functionsToExport.getNfts();

      setUserNFTs(nftData);
      minted && setMinted(false);
    };
    account && NFTS();
  }, [account, minted]);
  return (
    <Web3Context.Provider
      value={{
        update,
        account,
        userNFTs,
        ...functionsToExport,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
export default Web3Context;
