import "./chains.css";
import React from "react";
import avax from "../../assets/chain_img/avax.svg";
import one from "../../assets/chain_img/one.svg";
import matic from "../../assets/chain_img/matic.svg";
import bsc from "../../assets/chain_img/bsc.svg";

const Chains = () => {
  return (
    <div className="chains-main">
      <div className="chains-body-inner">
        <div className="chains-body-image">
          <img src={avax} alt="avax" />
        </div>
        <div className="chains-body-support">AVAX</div>
      </div>
      <div className="chains-body-inner">
        <div className="chains-body-image">
          <img src={one} alt="one" />
        </div>
        <div className="chains-body-support">ONE</div>
      </div>
      <div className="chains-body-inner">
        <div className="chains-body-image">
          <img src={matic} alt="M" />
        </div>
        <div className="chains-body-support">
          Polygon
          <br />
          (MATIC)
        </div>
      </div>
      <div className="chains-body-inner">
        <div className="chains-body-image">
          <img src={bsc} alt="avax" />
        </div>
        <div className="chains-body-support">BSC</div>
      </div>
    </div>
  );
};

export default Chains;
