import React from "react";
import "./button.css";

export const EzButton = (props) => {
  return (
    <div className="button-main">
      <a href={props.linkto} target="_blank" rel="noreferrer">
        <button className="the-button">Visit Website</button>
      </a>
    </div>
  );
};

export const ComingSoon = (props) => {
  return (
    <div className="button-main">
      <button className="not-a-button">Coming Soon</button>
    </div>
  );
};
