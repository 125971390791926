import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import "./Whitelist.css";
// import nftCard from "../../assets/card.svg";
import whitelistImg from "../../images/wl_logo.svg";
import pie from "../../images/pie.png";
import arrow from "../../images/arrow.png";
import tiles from "../../images/tiles.png";
import ghost_base from "../../images/ghost_base.png";
import ghost_crown from "../../images/ghost_crown.png";
import ghost_belt from "../../images/ghost_belt.png";
import ghost_headphones from "../../images/ghost_headphones.png";
import ghost_glasses from "../../images/ghost_glasses.png";
import WLCard from "../../components/whitelist/WLCard";
import Counter from "../../components/counter/counter";
import Web3Context from "../../contexts/Web3Context";
import GraphCalc from "../../components/graph/graph";

export default function Whitelist() {
  const { account, balance, givenVouchers, connectWallet } =
    useContext(Web3Context);
  const [vouchers, setVouchers] = useState(null);
  const [userWhitelists, setUserWhitelists] = useState(null);
  const [open, setOpen] = useState(false);
  const colOptions = [
    "#ff8c0050",
    "#00d9ff50",
    "#8400ff50",
    "#ff002650",
    "#00ff8450",
  ];
  let nftGroup = [];
  let randint;
  for (let i = 0; i < 50; i++) {
    randint = Math.floor(Math.random() * 5);
    nftGroup.push(
      <div
        className="ill-single"
        style={{
          backgroundColor: colOptions[randint],
          border: `1px solid ${colOptions[randint].slice(0, -2)}`,
        }}
      ></div>
    );
  }
  let randGroup = [];
  for (let i = 0; i < 5; i++) {
    randint = Math.floor(Math.random() * 4);
    randGroup.push(
      <div
        className="ill-single"
        style={{
          backgroundColor: colOptions[randint],
          border: `1px solid ${colOptions[randint].slice(0, -2)}`,
        }}
      ></div>
    );
  }

  let fixedGroup = [];
  for (let i = 0; i < 5; i++) {
    fixedGroup.push(
      <div
        className="ill-single"
        style={{
          backgroundColor: colOptions[i],
          border: `1px solid ${colOptions[i].slice(0, -2)}`,
        }}
      ></div>
    );
  }

  useEffect(() => {
    const getVouchers = async () => {
      const vouchersCount = await givenVouchers();
      setVouchers(vouchersCount);
    };
    const getUserWhitelists = async () => {
      const whitelistCount = await balance();
      setUserWhitelists(whitelistCount);
    };

    account && getUserWhitelists() && getVouchers();
  }, [account]);
  return (
    <>
      <div className="home-main">
        <Navbar />
        <div className="section-divider" id="home"></div>
        <div className="home-logo wl-landing">
          <div data-aos="fade-right" className="home-logo-main">
            <WLCard />
            {/* <div className="eztools">
              <span>Ez</span>Tools
            </div> */}
          </div>

          <div
            data-aos="fade-left"
            className="home-logo-title"
            id="rem-padding"
          >
            <div className="inline-wrap" id="hero-title">
              Introducing,
              <br /> EZTools <span>NFTs</span>
              {/* Build Something Great <span> With&nbsp;Us</span> */}
            </div>
            <div className="hero-desc" id="no-ml">
              <p>
                We at EzTools want our community and supporters to have a way to
                invest/support us and our vision of helping projects by
                providing them high-quality tools and services to build upon.
                For this, we're launching EzTools NFTs. You can now support us
                and earn by holding our NFTs.{" "}
              </p>
              <div className="presale-heading">Launching Presales</div>
              <p>
                We are running a presale of 100 NFTs for our Harmony community.
                Purchase of one or multiple presale NFTs makes you an OG
                investor in the project and allows you to receive a discount as
                well as exclusive perks for it. There are only 100 presale
                tokens available, make sure to grab one before they run out!!
              </p>
            </div>
          </div>
        </div>
        <div className="home-logo logo-wl">
          <div data-aos="fade-right" className="wl-logo-main tiles-contain">
            <div className="whitelist-img tiles">
              <img src={tiles} alt="" />
              <img src={ghost_base} alt="" className="overlay-img ghost-1" />
              <img src={ghost_crown} alt="" className="overlay-img ghost-2" />
              <img src={ghost_belt} alt="" className="overlay-img ghost-3" />
              <img src={ghost_glasses} alt="" className="overlay-img ghost-4" />
              <img
                src={ghost_headphones}
                alt=""
                className="overlay-img ghost-5"
              />
            </div>
          </div>
          <div data-aos="fade-left" className="hero-desc">
            <div className="sub-heading  sub-heading-responsive">
              How does the&nbsp;<span className="pink">NFT</span>&nbsp;work?
            </div>
            <p>
              Owning an EzTool NFT essentially makes you a part of revenue share
              of all the tools and services* EzTools provides. This means that
              up to December 2023, EzTool NFT holders would receive a share of
              the revenue generated (Refer to our Revenue Model). We've
              explained this in detail in the Staking the NFT section. The NFT
              has the following traits:
            </p>
            <ul style={{ listStyleType: "disc", marginLeft: 10 }}>
              <li>Headwear (4 attributes)</li>
              <li>Body (4 Attributes)</li>
              <li>Eyes (4 Attributes)</li>
              <li>Background (5 Attributes)</li>
            </ul>
            <p>
              The NFTs are generated randomly, with all traits having equal
              weightage and the same value. However, a combination of multiple
              traits across multiple NFTs would lead you to have a bigger share
              in revenue (check staking). The NFT sales would be launched in the
              following stages -
            </p>
            <br />
          </div>
        </div>
        <div className="whitelist-desc">
          <div className="wl-onecol">
            <div className="onecol-head" custattr="Only on Harmony Network">
              Whitelisting and Presale
            </div>
            <div>
              As Harmony is our genesis chain, and most of our initial community
              is from harmony, we would be releasing 100 NFTs at a discounted
              price of 100 dollars. Users with a whitelist voucher would get it
              at a further discounted price of 70 dollars
            </div>
          </div>
          <div className="wl-onecol">
            <div className="onecol-head" custattr="On all Ethereum Networks">
              Main Sale
            </div>
            <div>
              We would be launching the main sale for all networks on our
              website after the presale and launch of our new products. A total
              of 2155 NFTs will be available for sale, at a price of 150 dollars
              each.
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="home-logo logo-wl">
          <div data-aos="fade-right" className="wl-logo-main">
            <div className="whitelist-img">
              <img src={pie} alt="" />
            </div>
            <br />
            <div className="whitelist-desc">
              <div className="wl-onecol">
                <div
                  className="onecol-head"
                  custattr="Regular single NFT, any attribute"
                >
                  Regular NFTs
                </div>
                <div>
                  These are regular NFTs that are staked in the contract
                </div>
              </div>
              <div className="wl-onecol">
                <div
                  className="onecol-head"
                  custattr="Group of 5 NFTs with different backgrounds"
                >
                  Combo NFTs
                </div>
                <div>
                  A Regular NFT becomes a Combo NFT, if it's in a group of 5,
                  with 5 different background colors. Therefore in order to have
                  combo NFTs, you must have atleast 1 NFT of each background
                  type and staked them together in the contract.
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" className="hero-desc grid-reverse">
            <div className="sub-heading  sub-heading-responsive">
              How does <span className="pink">Staking</span> work?
            </div>
            <p>
              The NFT holders would receive the “NFT Holders” portion of the
              Revenue. In order to receive a portion of the ezTools revenue, you
              must have your NFT staked to the payout contract. The payout would
              be monthly/bi-monthly depending upon the amount and EzTools team
              would be annonuncing the payout dates well ahead.
              <br />
              <br /> The NFT holders must ensure that all of their NFTs are
              staked (in the optimum order) during that period. On the payout
              date, the NFTs would be classified into the following two:
            </p>
            <ul style={{ listStyleType: "disc", marginLeft: 10 }}>
              <li>Regular NFTs</li>
              <li>Combo NFTs</li>
            </ul>
            <p>Once the staked NFTs are in, the distribution is as follows:</p>
            <ul style={{ listStyleType: "disc", marginLeft: 10 }}>
              <li>
                The common NFT gets <span className="pink">1x</span> share of
                the Revenue
              </li>
              <li>
                The Combo NFT gets <span className="pink">2x</span> share of the
                revenue.
              </li>
            </ul>
          </div>
        </div>
        <div className={`expandable-div ${open && `expanded`}`}>
          <div className="col-heading">
            An <span className="pink">Example</span>
          </div>
          <div className="tac">
            Let's take an example to see this: Suppose there are{" "}
            <span className="pink">50 NFTs staked</span>, out of which{" "}
            <span className="pink">5 are Combo NFTs (1 set)</span> and the rest
            <span className="pink"> 45 are Regular NFTs.</span>
          </div>
          <br />
          <div className="rev-calc">
            <div className="rev our-rev">${1100}</div>
            <div className="rev rev-ratio">x 20% =</div>
            <div className="rev your-rev">${(1100 * 0.2).toFixed(0)}</div>
          </div>
          <div className="illustration">
            <div className="ill-total">{nftGroup}</div>
            <div className="ill-breakdown">
              <div
                className="ill-breakdown-item"
                custattr="5 Common NFTs"
                person="Person A"
              >
                {randGroup}
              </div>
              <div
                className="ill-breakdown-item"
                custattr="5 Combo NFTS"
                person="Person B"
              >
                {fixedGroup}
              </div>
            </div>
          </div>
          <br />
          <div className="tac mb5">
            <hr />
            Total effective share count:{" "}
            <span className="com-mul" custattr="common">
              45 x 1
            </span>{" "}
            +{" "}
            <span className="com-mul" custattr="custom">
              5 x 2
            </span>{" "}
            ={" "}
            <span className="pink" id="big-text">
              55
            </span>
          </div>
          <div className="tac">
            The price cut per share: <span className="pink">220$ / 55</span> ={" "}
            <span className="pink" id="big-text">
              4$
            </span>
            <br />
            <br />
            <hr />
            <br />
          </div>
          <div className="tac">Share earning per NFT</div>
          <div className="tac">
            <span className="pink com-mul" id="big-text" custattr="Common">
              4$
            </span>
            <span className="pink" id="big-text">
              &nbsp;|&nbsp;
            </span>
            <span className="pink com-mul" id="big-text" custattr="Combo">
              8$
            </span>
            <br />
            <br />
          </div>
          <br />
          <div className="tac">
            In this case, for every&nbsp;{" "}
            <span className="pink" id="big-text">
              1100$
            </span>{" "}
            &nbsp;EzTools makes
          </div>
          <div className="tac">
            Person A makes&nbsp;{" "}
            <span className="pink" id="big-text">
              20$,
            </span>
            &nbsp;and Person B makes&nbsp;{" "}
            <span className="pink" id="big-text">
              40$
            </span>
          </div>
        </div>
        <div className="btn-container">
          <button
            className="collapse-btn"
            onClick={() => setOpen((val) => !val)}
          >
            <img
              src={arrow}
              alt=""
              className={`arrow-img ${open && "arrow-img-expanded"} `}
            />
            {open ? `collapse` : "expand"}
          </button>
        </div>
        <div className="section-heading heading-responsive">
          <span>Whitelists</span>
        </div>
        <div className="home-logo logo-wl">
          <div data-aos="fade-right" className="wl-logo-main">
            <div className="whitelist-img">
              <img src={whitelistImg} alt="" />
            </div>
            <Counter vouchers={vouchers} />
          </div>

          <div data-aos="fade-left" className="hero-desc">
            <div className="sub-heading  sub-heading-responsive">
              Benefits of a&nbsp;<span>whitelist</span>
            </div>
            <p>
              Being whitelisted ensures you get to mint before everyone else as
              well as gets you an extra discount for the mints. There are 30
              whitelist slots that can be redeemed in a 3 day duration before
              presale, you receive a 30$ discount on the presale as well
              therefore only costing 70$ per NFT purchased through a whitelist
              voucher.
            </p>
            <div className="sub-heading  sub-heading-responsive">
              How to get&nbsp;<span>whitelisted?</span>
            </div>
            {/* <p>
              You can get a whitelist voucher by participating in various
              contests and AMAs being organized right now and being active in
              the community.
            </p> */}

            <div>
              <ul style={{ listStyleType: "disc", marginLeft: 10 }}>
                <li>Participate in Discord contests</li>
                <li>Follow us on twitter and engage with our tweets</li>
                <li> Attend upcoming AMAs</li>
              </ul>
            </div>
            <div className="sub-heading  sub-heading-responsive">
              Your&nbsp;<span>whitelists :</span>&nbsp;
              {!account ? (
                <button
                  className="wallet-connect-button"
                  onClick={connectWallet}
                >
                  Connect your wallet
                </button>
              ) : (
                <>{userWhitelists}</>
              )}
            </div>
          </div>
        </div>
        <div className="section-divider" id="projects"></div>
        <GraphCalc />
      </div>
    </>
  );
}
