import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import discord from "../../assets/discordNew.svg";
import nft from "../../assets/nft.png";
import your from "../../assets/yournfts.png";
import mint from "../../assets/mint.png";
import Web3Context from "../../contexts/Web3Context";
import ghost from "../../images/Little ghost.png";

export default function Navbar() {
  const { account, connectWallet } = useContext(Web3Context);
  // useEffect(() => {
  //   if (!account) {
  //     connectWallet();
  //     console.log("connected");
  //   }
  // }, [account, connectWallet]);
  return (
    <>
      <div className="home-navbar flex ">
        {/* navigate to / on click */}
        <div
          className="img-wrap"
          onClick={() => {
            window.open("/", "_self");
          }}
        >
          <img src={ghost} alt="mascot" />
        </div>

        <Link to="/#home">Home </Link>

        {/* <a href="#milestones">Timeline</a> */}
        {/* <a href="/#projects">Projects</a>
        <a href="/#developers">Developers</a> */}
        <a
          href="https://discord.gg/n4wvJcJ8BB"
          target="_blank"
          rel="noreferrer"
        >
          Discord
        </a>
        <Link to="/whitelist">Whitelist </Link>
        <div style={{ display: "flex", gap: 15 }} className="responsive-hide">
          <div
            className="responsive-div"
            onClick={() => {
              window.open("https://discord.gg/n4wvJcJ8BB");
            }}
          >
            <img src={discord} alt="discord" className="discord-img" />
            Discord
          </div>
          <div
            className="responsive-div"
            onClick={() => {
              window.open("/whitelist", "_self");
            }}
          >
            <img src={nft} alt="whitelist" className="nft-img" />
            Whitelist
          </div>
          {/* <Link to="/mint">Mint</Link> */}
          {<Link to="/collection">My NFTs</Link>}
          <div
            className="responsive-div"
            onClick={() => {
              window.open("/mint", "_self");
            }}
          >
            <img src={mint} alt="mint" className="nft-img" />
            Mint
          </div>
          <div
            className="responsive-div"
            onClick={() => {
              window.open("/collection", "_self");
            }}
          >
            <img src={your} alt="whitelist" className="nft-img" />
            Your NFTs
          </div>

          {/* <div>
            {!account ? (
              <button className="wallet-connect-button" onClick={connectWallet}>
                <img src={wallet} style={{ height: 25 }} alt="" />
                Connect
              </button>
            ) : (
              <button
                className="wallet-connect-button"
                // href="/wallet-connect"
              >
                {`${account?.slice(0, 6)}...${account?.slice(-4)}`}
              </button>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
