import React from "react";
import Project from "../projCard/proj";
import escrow from "../../assets/escrow.png";
import airdrop from "../../assets/airdrop.png";
import staking from "../../assets/staking.png";
import multisig from "../../assets/multisig.png";
import migrate from "../../assets/migrate.png";
import bots from "../../assets/bots.png";
import launch from "../../assets/launch.png";
import "./projsect.css";

// import FadeIn from "react-fade-in";

const ProjectsList = (props) => {
  const launchdesc =
    "Launch your Token with basic features like vesting and continuous release without any code.";
  const botsdesc =
    "Every community deserves to feel special and out custom discord bot allows you to do exactly this! Want to token gate channels or provide games for your community? all possible through our EzBots service where we build a custom discord bot just for your community.";
  const migratedesc =
    "Going cross chain or migrating from v1 to v2, if you have migration and snapshot needs our service would make the process easier for you. Just 2 clicks and user's tokens can be migrated to a new contract or an entirely new chain, we can manage it all without you spending any extra resources.";
  const stakingdesc =
    "Want to provide utility to your NFT holders? Nothing is better than a staking platform that allows you to reward your early investors. EzStake provides an easy to use interface for your users to stake and earn rewards in whichever token you want. It's easy to setup and extremely flexible. With this tool you can save precious dev time and resources and build something that adds value to your community.";
  const airdropdesc =
    "If you want to send out airdrops for any type of token to hundreds of users there's simply no better and cheaper alternative than EzTools' EzDrops. Simply select the tokens you want to drop and airdrop it to hundreds of users with a single click.";
  const escrowdesc =
    "Trying to trade an NFT that has not been listed yet? Or want to sell it P2P but need a trustless platform? EzScrow is the solution where users can list their NFT, receive an unlisted link for it and then share it with the buyer directly to make a sale, these listings can be reserved or public and will stay between the two parties involved.";
  const multisigdesc =
    "A new take on signature based multisig with transaction batching, transaction templates and more.";
  return (
    <div className="pl-main">
      {/* <FadeIn delay={300} visible={true}> */}
      <Project
        rl="right"
        title="EzDrops"
        desc={airdropdesc}
        image={airdrop}
        deployed={true}
        linkto={"https://airdrop.eztools.one/"}
        name={
          <div>
            <span>Ez</span>Drops
          </div>
        }
      />
      <Project
        rl="left"
        title="EzStaking"
        desc={stakingdesc}
        image={staking}
        linkto={"https://staking.eztools.one/"}
        name={
          <div>
            <span>Ez</span>Staking
          </div>
        }
      />
      <Project
        rl="right"
        title="EzBots"
        desc={botsdesc}
        image={bots}
        linkto={"https://bots.eztools.one/"}
        name={
          <div>
            <span>Ez</span>Bots
          </div>
        }
      />
      <Project
        rl="left"
        title="EzMigrate"
        desc={migratedesc}
        image={migrate}
        linkto={"https://migrate.eztools.one/"}
        name={
          <div>
            <span>Ez</span>Migrate
          </div>
        }
      />
      <Project
        rl="right"
        title="EzLaunch"
        desc={launchdesc}
        linkto={""}
        deployed={true}
        image={launch}
        name={
          <div>
            <span>Ez</span>Launch
          </div>
        }
      />
      <Project
        rl="left"
        title="EzSig"
        desc={multisigdesc}
        image={multisig}
        linkto={""}
        name={
          <div>
            <span>Ez</span>Sig
          </div>
        }
      />
      <Project
        rl="right"
        title="EzScrow"
        desc={escrowdesc}
        image={escrow}
        linkto={""}
        name={
          <div>
            <span>Ez</span>Scrow
          </div>
        }
      />
      {/* </FadeIn> */}
    </div>
  );
};

export default ProjectsList;
