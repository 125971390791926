import React from "react";
import "./WLCard.css";

function WLCard() {
  return (
    <div className="card">
      <div className="card-shine"></div>
      <img src="/card.svg" alt="" />
    </div>
  );
}

export default WLCard;
