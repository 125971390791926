import { useEffect, useContext, useState } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./popup.module.css";
// import rarityData from "./whaleRarity.json";
// import Web3Context from "../../contexts/Web3Context";
// import { ethers } from "ethers";
// import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid hotpink",
    background: "#16151a",
    backdropFilter: "blur(24px)",
    borderRadius: "25px",
    width: "95%",
    maxWidth: "1000px",
    // height: "480px",

    padding: "0px 20px",
  },
  overlay: {
    backgroundColor: "rgba(9, 8, 13, 0.6)",
    zIndex: "50",
    backdropFilter: "blur(10px)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

export default function NFTPopup({ ghost, open, setModal, loading }) {
  //   const { generateLink, contractDetails } = useContext(Web3Context);
  const [rarity, setRarity] = useState({
    Background: null,
    Accessory: null,
    Headwear: null,
    Weapon: null,
    Colour: null,
    Eye: null,
  });

  const getTokenId = (tokenName) => {
    if (!tokenName) return;
    const token = tokenName.split(" ");
    return token[token.length - 1];
  };
  // useEffect(() => {
  //   setRarity({
  //     Background: rarityData.Background[ghost?.attributes[1].value],
  //     Accessory: rarityData.Accessory[ghost?.attributes[3].value],
  //     Headwear: rarityData.Headwear[ghost?.attributes[4].value],
  //     Weapon: rarityData.Weapon[ghost?.attributes[5].value],
  //     Colour: rarityData.Color[ghost?.attributes[6].value],
  //     Eye: rarityData.Eye[ghost?.attributes[7].value],
  //   });
  // }, [ghost]);
  //   const [tokenAddress, setTokenAddress] = useState(ALL_TOKENS["NATIVE"]);
  const [isCustom, setIsCustom] = useState(false);
  const [state, setState] = useState({
    receiver: "0x0000000000000000000000000000000000000000",
  });
  // console.log(ghost);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00000";
  }
  // console.log(rarityData.Color);
  return (
    <div>
      {/* {console.log(whales[0]?.name)} */}
      <Modal isOpen={open} onAfterOpen={afterOpenModal} style={customStyles}>
        {loading ? (
          <div className={styles.loading}>loading...</div>
        ) : (
          <>
            <img
              src="/close.png"
              className={styles.close}
              onClick={() => setModal(false)}
            />
            <div className={styles.main}>
              <img src={ghost?.image} className={styles.image} />
              <div className={styles.content}>
                <div className={styles.name}>
                  EzTools NFT <span>{getTokenId(ghost?.name)}</span>
                </div>
                <div className={styles.attr}>
                  <div className={styles.attribute}>
                    Background:{" "}
                    <span className={styles.attrval}>
                      {ghost?.attributes[0].value}
                    </span>
                  </div>
                  <div className={styles.attribute}>
                    Body:{" "}
                    <span className={styles.attrval}>
                      {ghost?.attributes[1].value}
                    </span>
                  </div>
                  <div className={styles.attribute}>
                    Clothing:{" "}
                    <span className={styles.attrval}>
                      {ghost?.attributes[2].value}
                    </span>
                  </div>

                  <div className={styles.attribute}>
                    Headwear:{" "}
                    <span className={styles.attrval}>
                      {ghost?.attributes[3].value}
                    </span>
                  </div>
                  <div className={styles.attribute}>
                    Expression:{" "}
                    <span className={styles.attrval}>
                      {ghost?.attributes[4].value}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}
